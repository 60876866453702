import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      legalAccept: 'false',
      multiLanguage: '/',
      isLoggedIn: false,
      user: '',
      user_point: '',
      point_balance: 0,
      badgeCount: 0,
      server_status: '',
      access_token: null,
      countries: [],
      states: [],
      displayPointExpiryDate: '-',
      displayExpireSoonPoint: 0,
      tempRegisterUserData: '',
    }
  },
  getters: {},
  mutations: {
    toggleServer(state, status) {
      state.server_status = status;
    },
  },
  actions: {},
  modules: {},
});
