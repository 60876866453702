import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { MotionPlugin } from '@vueuse/motion'
import VueLazyload from 'vue-lazyload';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import messages from './il8n';

// import iban from './assets/lang/iban.json';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();


export const i18n = new createI18n({
 locale: 'en',
 messages,
});

const vue_tel_input_options = {
	'defaultCountry': 'my',
	'inputOptions': {
		maxlength: 14,
	},
	'dropdownOptions': {
		'showSearchBox': true,
		'showDialCodeInSelection': true
	},
	'mode': 'national',
	// 'customValidate': '/^\+\d+$/',
	'styleClasses': 'ac_vuetelinput'
};

createApp(App)
.use(VueTelInput, vue_tel_input_options)
.use(VueLazyload)
.use(BootstrapVue3)
.use(store)
.use(router)
.use(i18n)
.use(vSelect)
.use(BootstrapIconsPlugin)
.mount('#app');
