<template>
  <AgeLegal v-if="this.$store.state.legalAccept != 'true'"></AgeLegal>
  <Header_info></Header_info>
  <router-view :key="$route.fullPath"></router-view>
  <Footer_info></Footer_info>
  <Loader_component v-if="!isLoaded" :loading="true" />
</template>

<script>
import Footer_info from "@/include/footer_info.vue";
import Header_info from "@/include/header_info.vue";
import AgeLegal from "@/views/AgeLegal.vue"
import { getAPI, getGuestID } from "./modules/general";
// import { i18n } from './main';
import Loader_component from "@/include/loader_component.vue";
import globals from "@/config/globals.js";
import { verifyLoginAPI } from "./modules/auth";

export default {
  data() {
    return {
      isLoaded: false,
    };
  },
  async created() {

    if (!localStorage.getItem('language')) {
      var languageSelected = (this.$route.params.lang != 'en' && this.$route.params.lang != 'cn') ? 'en' : this.$route.params.lang;
      localStorage.setItem("language", languageSelected);
    }
    this.$store.state.legalAccept = localStorage.getItem('legalCondition');

    var generalDataResponse = await getAPI('/general');

    if (generalDataResponse.code == "success") {
      globals.categories = generalDataResponse.data.categories ? generalDataResponse.data.categories : [];
      globals.customer_groups = generalDataResponse.data.customer_groups ? generalDataResponse.data.customer_groups : [];
      globals.variant_types = generalDataResponse.data.variantTypes ? generalDataResponse.data.variantTypes : globals.variantTypes;
      globals.payment_methods = generalDataResponse.data.payment_methods ? generalDataResponse.data.payment_methods : globals.payment_methods;

      this.$store.commit('toggleServer', generalDataResponse.data.status);
      if (this.$store.state.server_status == 'maintenance') {
        this.$router.push({ name: 'MaintenancePage' })
      }
      const localisationLibResponse = await fetch('https://api.1602craftbeer.com/lib/localisation.json');
      const generalDataLocalisationResponse = await localisationLibResponse.json();
      if (generalDataLocalisationResponse.code == "success") {
        this.$store.state.states = generalDataLocalisationResponse.data.states ? generalDataLocalisationResponse.data.states : [];
        this.$store.state.countries = generalDataLocalisationResponse.data.countries ? generalDataLocalisationResponse.data.countries : [];
        localStorage.states = JSON.stringify(this.$store.state.states);
        localStorage.countries = JSON.stringify(this.$store.state.countries);
      }
    }

  },
  async mounted() {
    
    // const generalData = await getAPI('/general');
    this.$store.state.access_token = localStorage.access_token ? localStorage.access_token : "";
    if (this.$store.state.access_token.trim()) {
      var verifyLoginResponse = await verifyLoginAPI();
      if (verifyLoginResponse.code == "success") {
        this.$store.state.isLoggedIn = true;
        globals.user = verifyLoginResponse.data;
      } else {
        this.$store.state.isLoggedIn = false;
        this.$store.state.access_token = null;
        localStorage.access_token = "";
      }
    }

    var cartUser = {}
    if (!this.$store.state.isLoggedIn) {
      cartUser['guest_id'] = getGuestID();
    }

    var getCartCountResponse = await getAPI("/cart/count", cartUser)
    if (getCartCountResponse.code == "success") {
      this.$store.state.badgeCount = getCartCountResponse.data.count
    }

    if (this.$store.state.isLoggedIn == true) {
      var UserPointResponse = await getAPI("/user/point");
      if (UserPointResponse.code == "success") {
        this.$store.state.user_point = UserPointResponse.data;
        this.$store.state.point_balance = UserPointResponse.data.point_balance.toLocaleString();
      }

      var UserResponse = await getAPI("/user/profile");
      if (UserResponse.code == "success") {
        this.$store.state.user = UserResponse.data;
      }
    }


    window.scrollTo(0, 0);
    this.isLoaded = true;
  },
  components: {
    Footer_info,
    Header_info,
    AgeLegal,
    Loader_component
  },
  methods: {

  },
};
</script>
<style lang="scss">
@import "./scss/style.scss";
</style>
