<template>
  <header>
    <div class="section_main_head">
      <section class="header_banner">
        <div class="container">
          <p>{{ $t('welcome_1602') }} <img src="/images/icon/cheer.png" alt="cheer icon" /></p>
        </div>
      </section>
      <section class="section_header">
        <div class="container">
          <div class="header-content">
            <div class="header-content-left">
              <div class="header-content-left-image">
                <router-link :to="`${this.$store.state.multiLanguage}`">
                  <img src="/images/logo/1602_logo.png" alt="1602 logo" />
                </router-link>
              </div>
              <div class="header-content-left-navigation">
                <div class="header-content-left-navigation-item">
                  <router-link :to="`${this.$store.state.multiLanguage}`">
                    {{ $t('home') }}
                  </router-link>
                </div>
                <div class="header-content-left-navigation-item">
                  <div :class="navigationBeer ? 'navigation-item-dropdown-active' : 'navigation-item-dropdown'"
                    @click="triggerHeader('beer')">
                    {{ $t('our_beers') }}
                    <img
                      :src="navigationBeer ? '/images/icon/dropdown_arrow_active.png' : '/images/icon/dropdown_arrow.png'"
                      alt="dropdown arrow" />
                  </div>
                  <div class="navigation-item-dropdown_item" @mouseleave="navigationBeer = false" v-if="navigationBeer">
                    <div class="navigation-item-dropdown_item-container">
                      <section>
                        <p class="navigation-item-dropdown_item-container-item_main">{{ $t('signature_beer') }}</p>
                        <router-link :to="`${this.$store.state.multiLanguage}` + 'our_beer/lager'">{{
            $t('lager_beer_data.main_name')
          }}</router-link>
                        <router-link :to="`${this.$store.state.multiLanguage}` + 'our_beer/pale_ale'">{{
            $t('pale_ale_data.main_name')
          }}</router-link>
                        <router-link :to="`${this.$store.state.multiLanguage}` + 'our_beer/extra_dark'">{{
            $t('extra_dark_data.main_name')
          }}</router-link>
                        <router-link :to="`${this.$store.state.multiLanguage}` + 'our_beer/wheat_beer'">{{
            $t('wheat_data.main_name')
          }}</router-link>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="header-content-left-navigation-item">
                  <router-link :to="`${this.$store.state.multiLanguage}` + 'our_story'">
                    {{ $t('our_story') }}
                  </router-link>
                </div>
                <div class="header-content-left-navigation-item">
                  <!-- <router-link :to="`${this.$store.state.multiLanguage}` + 'shop'">
                    {{ $t('shop') }}
                  </router-link> -->
                  <a href="https://shop.1602craftbeer.com/">
                    {{ $t('shop') }}
                  </a>
                </div>
                <div class="header-content-left-navigation-item">
                  <div :class="navigationEvents ? 'navigation-item-dropdown-active' : 'navigation-item-dropdown'"
                    @click="triggerHeader('events')">
                    {{ $t('events_services') }}
                    <img
                      :src="navigationEvents ? '/images/icon/dropdown_arrow_active.png' : '/images/icon/dropdown_arrow.png'"
                      alt="dropdown arrow" />
                  </div>
                  <div class="navigation-item-dropdown_item" @mouseleave="navigationEvents = false"
                    v-if="navigationEvents">
                    <div class="navigation-item-dropdown_item-container">
                      <section>
                        <p class="navigation-item-dropdown_item-container-item_main">1602 {{ $t('events') }}</p>
                        <router-link :to="`${this.$store.state.multiLanguage}` + 'events#upcoming'"
                          :class="{ 'not-active': isActiveLink('#upcoming') }">{{ $t('upcoming_event') }}</router-link>
                        <router-link :to="`${this.$store.state.multiLanguage}` + 'events#past'"
                          :class="{ 'not-active': isActiveLink('#past') }">{{ $t('past_event') }}</router-link>
                      </section>
                      <section>
                        <p class="navigation-item-dropdown_item-container-item_main">{{ $t('our_services') }}</p>
                        <router-link :to="`${this.$store.state.multiLanguage}` + 'event_services'">{{
            $t('event_services') }}</router-link>
                        <router-link :to="`${this.$store.state.multiLanguage}` + 'events_form'">{{ $t('get_quote')
                          }}</router-link>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-content-right">
              <!-- <div class="header-content-right-profile">
                <router-link
                  :to="`${this.$store.state.multiLanguage}` + (this.$store.state.isLoggedIn == true ? (this.$store.state.user.phone_verified_at == null ? 'profile_account_details' : 'profile_dashboard') : 'login')">
                  <img src="/images/icon/profile.png">
                  <p>{{ this.$store.state.isLoggedIn == true ? $t('account') : $t('login_signup') }}</p>
                </router-link>
              </div> -->
              <!-- <div class="header-content-right-cart">
                <router-link :to="`${this.$store.state.multiLanguage}` + 'cart'">
                  <b-avatar src="" :badge="this.$store.state.badgeCount.toString()" badge-top>
                    <img src="/images/icon/cart.png" alt="cart">
                  </b-avatar>
                </router-link>
              </div> -->
              <div class="header-content-right-language">
                <a class="navigation-item-dropdown" @click="triggerHeader('language')">
                  {{ $t('switchLanguage') }}
                </a>
                <div class="navigation-item-dropdown_item" @mouseleave="navigationLanguage = false"
                  v-if="navigationLanguage">
                  <div class="navigation-item-dropdown_item-container">
                    <section>
                      <p class="navigation-item-dropdown_item-container-item_main">{{ $t('language') }}</p>
                      <a :class="this.$i18n.locale == 'en' ? 'active disabled' : ''"
                        @click="switchLanguage('en')">English</a>
                      <a :class="this.$i18n.locale == 'cn' ? 'active disabled' : ''"
                        @click="switchLanguage('cn')">中文</a>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_header_mobile">
        <div class="container">
          <div class="header_mobile-container">
            <div class="header_mobile-container-menu">
              <div class="header_mobile-container-menu-button">
                <!-- class="navbar-toggler" -->
                <button id="header_top-main-mobile" class="navbar-toggler collapsed" data-bs-toggle="offcanvas"
                  type="button" aria-controls="#offcanvasHeaderMobile" data-bs-target="#offcanvasHeaderMobile"
                  aria-expanded="false">
                  <span class="toggler-icon top-bar"></span>
                  <span class="toggler-icon middle-bar"></span>
                  <span class="toggler-icon bottom-bar"></span>
                </button>
              </div>
            </div>
            <div class="header_mobile-container-icon">
              <router-link :to="`${this.$store.state.multiLanguage}`">
                <img src="/images/logo/1602_logo.png" alt="1602 logo" />
              </router-link>
            </div>
            <div class="header_mobile-container-action">
              <img v-if="false" src="/images/icon/mobile_scan.png" alt="scan" />
              <router-link :to="`${this.$store.state.multiLanguage}` + 'cart'">
                <b-avatar v-if="false" src="" :badge="this.$store.state.badgeCount.toString()" badge-top>
                  <img v-if="false" src="/images/icon/cart.png" alt="cart">
                </b-avatar>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="section_header_menu-mobile">
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasHeaderMobile"
        aria-labelledby="offcanvasHeaderMobileLabel">
        <div class="offcanvas-header">
          <!-- <div class="header_menu-mobile-account">
            <div class="header_menu-mobile-account-condition">
              <router-link
                :to="`${this.$store.state.multiLanguage}` + (this.$store.state.isLoggedIn == true ? (this.$store.state.user.phone_verified_at == null ? 'profile_account_details' : 'profile_dashboard') : 'login')">
                <img src="/images/icon/mobile_profile.png" alt="profile mobile">
                <p data-bs-dismiss="offcanvas">{{ this.$store.state.isLoggedIn == true ? $t('account') :
            $t('login_signup') }}</p>
              </router-link>
            </div>
            <div v-if="this.$store.state.isLoggedIn == true" class="header_menu-mobile-account-point">
              <p>{{ this.$store.state.point_balance }} {{ $t('points') }}</p>
            </div>
          </div> -->
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="header_menu-mobile-list accordion" id="header_menu">
            <div class="header_menu-mobile-list-item accordion-item">
              <p class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  {{ $t('our_beers') }}
                </button>
              </p>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#header_menu">
                <div class="accordion-body">
                  <p data-bs-dismiss="offcanvas"><router-link
                      :to="`${this.$store.state.multiLanguage}` + 'our_beer/lager'">{{ $t('lager_beer_data.main_name')
                      }}
                    </router-link></p>
                  <p data-bs-dismiss="offcanvas"><router-link
                      :to="`${this.$store.state.multiLanguage}` + 'our_beer/pale_ale'">{{ $t('pale_ale_data.main_name')
                      }}
                    </router-link></p>
                  <p data-bs-dismiss="offcanvas"><router-link
                      :to="`${this.$store.state.multiLanguage}` + 'our_beer/extra_dark'">{{
            $t('extra_dark_data.main_name')
          }} </router-link></p>
                  <p data-bs-dismiss="offcanvas"><router-link
                      :to="`${this.$store.state.multiLanguage}` + 'our_beer/wheat_beer'">{{ $t('wheat_data.main_name')
                      }}
                    </router-link></p>
                </div>
              </div>
            </div>
            <div class="header_menu-mobile-list-item">
              <p class="header_menu-mobile-list-item-link" data-bs-dismiss="offcanvas"><router-link :to="`${this.$store.state.multiLanguage}` + 'our_story'">{{
            $t('our_story') }}</router-link>
              </p>
            </div>
            <div class="header_menu-mobile-list-item">
              <!-- <p class="header_menu-mobile-list-item-link" data-bs-dismiss="offcanvas"><router-link :to="`${this.$store.state.multiLanguage}` + 'shop'">{{ $t('shop') }}</router-link></p> -->
              <p class="header_menu-mobile-list-item-link" data-bs-dismiss="offcanvas"><a href="https://shop.1602craftbeer.com/">{{ $t('shop') }}</a></p>
            </div>
            <div class="header_menu-mobile-list-item accordion-item">
              <p class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  {{ $t('events_services') }}
                </button>
              </p>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#header_menu">
                <div class="accordion-body">
                  <p data-bs-dismiss="offcanvas"><router-link
                      :to="`${this.$store.state.multiLanguage}` + 'events#upcoming'"
                      :class="{ 'not-active': isActiveLink('#upcoming') }">{{ $t('upcoming_event') }}</router-link></p>
                  <p data-bs-dismiss="offcanvas"><router-link :to="`${this.$store.state.multiLanguage}` + 'events#past'"
                      :class="{ 'not-active': isActiveLink('#past') }">{{ $t('past_event') }}</router-link></p>
                  <p data-bs-dismiss="offcanvas"><router-link
                      :to="`${this.$store.state.multiLanguage}` + 'event_services'">{{ $t('event_services')
                      }}</router-link></p>
                  <p data-bs-dismiss="offcanvas"><router-link :to="`${this.$store.state.multiLanguage}` + 'events_form'">{{ $t('get_quote')
                      }}</router-link></p>
                </div>
              </div>
            </div>
            <div class="header_menu-mobile-list-item" v-if="this.$store.state.isLoggedIn == true">
              <p class="header_menu-mobile-list-item-link" data-bs-dismiss="offcanvas" @click="logOut">
                {{ $t('profile_side_menu.log_out') }}
              </p>
            </div>
          </div>
        </div>
        <div class="header_menu-mobile-footer">
          <div class="header_menu-mobile-footer-info">
            <div class="header_menu-mobile-footer-info-social">
              <a href="https://www.facebook.com/1602craftbeer/" target="_blank"><img
                  src="/images/icon/facebook.png" alt="facebook" /></a>
              <a href="https://www.instagram.com/1602craftbeer_/" target="_blank"><img
                  src="/images/icon/instagram.png" alt="instagram" /></a>
              <a href="https://www.youtube.com/@CraftBeer-db2sn" target="_blank"><img
                  src="/images/icon/youtube.png" alt="youtube" /></a>
            </div>
            <div class="header_menu-mobile-footer-info-data">
              <div class="header_menu-mobile-footer-info-data-content mobile-footer-info-email">
                <img src="/images/icon/mail.png" alt="email" />
                <p><a href="mailto:globalsales998@gmail.com">globalsales998@gmail.com</a></p>
              </div>
              <div class="header_menu-mobile-footer-info-data-content mobile-footer-info-phone">
                <img src="/images/icon/phone.png" alt="phone" />
                <p><a href="tel:1-300-88-3456">1-300-88-3456</a></p>
              </div>
            </div>
          </div>
          <hr />
          <div class="header_menu-mobile-footer-language">
            <div class="accordion" id="language-selection">
              <div class="accordion-item">
                <p class="accordion-header" id="header_language">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseLanguage" aria-expanded="true" aria-controls="collapseLanguage">
                    {{ $t('switchLanguage') }} </button>
                </p>
                <div id="collapseLanguage" class="accordion-collapse collapse" aria-labelledby="header_language"
                  data-bs-parent="#language-selection">
                  <div class="accordion-body">
                    <p><a :class="this.$i18n.locale == 'en' ? 'active disabled' : ''"
                        @click="switchLanguage('en')">English</a></p>
                    <p><a :class="this.$i18n.locale == 'cn' ? 'active disabled' : ''"
                        @click="switchLanguage('cn')">中文</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="header_padding"></section>
  </header>
</template>

<script>
// import { i18n } from '../main';
import { logoutAPI } from '@/modules/auth';

export default {
  name: 'HeaderPage',
  data() {
    return {
      navigationBeer: false,
      navigationEvents: false,
      navigationLanguage: false,
      multiLanguage: this.$store.state.multiLanguage
    };
  },
  watch: {
    '$route.params.lang': {
      handler(newLang) {
        // Update content or perform language-specific actions based on newLang
        localStorage.setItem("language", newLang != 'cn' ? "en" : 'cn');
        this.$i18n.locale = localStorage.getItem("language");
        this.$store.state.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;
        // this.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;
      },
    },
  },
  mounted() {
  },
  async created() {
    this.$i18n.locale = localStorage.getItem("language");
    this.$store.state.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;
    // this.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;
  },
  methods: {
    isActiveLink(hash) {
      // Check if the current route matches exactly and has the specified hash
      return this.$route.fullPath != `${this.$store.state.multiLanguage}events${hash}`;
    },
    async logOut() {
      await logoutAPI();
      // window.location = "/";
      this.$router.push(`${this.$store.state.multiLanguage}`);
    },
    async switchLanguage(language) {
      localStorage.setItem("language", language);
      this.$i18n.locale = localStorage.getItem("language");
      this.$store.state.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;

      // this.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;

      const currentPath = this.$route.path;
      var newPath = '';
      var languageArray = ['cn', 'en'];

      if (languageArray.includes(language)) {
        newPath = '';

        if (language == 'en') {
          newPath = currentPath.replace(/^\/(cn)/, '');

          this.$router.push(newPath);
        } else {
          newPath = `/${language}${currentPath}`;
          this.$router.push(newPath);
        }
      }
    },
    triggerHeader(navigation) {
      switch (navigation) {
        case 'beer':
          if (this.navigationBeer == true) {
            this.navigationBeer = !this.navigationBeer;
          }
          else {
            this.navigationBeer = true;
            this.navigationEvents = false;
            this.navigationLanguage = false;
          }
          break;
        case 'events':
          if (this.navigationEvents == true) {
            this.navigationEvents = !this.navigationEvents;
          }
          else {
            this.navigationEvents = true;
            this.navigationBeer = false;
            this.navigationLanguage = false;
          }
          break;
        case 'language':
          if (this.navigationLanguage == true) {
            this.navigationLanguage = !this.navigationLanguage;
          }
          else {
            this.navigationLanguage = true;
            this.navigationEvents = false;
            this.navigationBeer = false;
          }
          break;
      }
    },
  },
  components: {}
}
</script>
