//1602 local api
const baseApiURL = "https://api.1602craftbeer.com";


var variantTypes = {
    type: "Type",
    size: "Size",
  };


export { baseApiURL, variantTypes };
