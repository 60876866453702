<template>
  <footer>
    <div class="section_whatsapp_action">
      <a href="https://wa.me/60162931602" target="_blank"><img src="/images/icon/whatsapp_fixed.png" /></a>
    </div>
    <section class="section_footer">
      <div class="footer_container">
        <div class="container">
          <img class="footer-logo" src="/images/logo/1602_logo.png" alt="1602 logo" />
          <div class="footer-head">
            <div class="footer-head-left">
              <div class="footer-foot-left-content footer-foot-left-name">
                <p>{{ $t('craft_beer_brewery') }}</p>
              </div>
              <!-- <div class="footer-foot-left-content footer-foot-left-address">
                <img src="/images/icon/location.png" alt="location" />
                <p>Ground Floor, Lot 19695, No. 17, Block 11, <br />Block B1, Saradise, 93350, Kuching, Sarawak</p>
              </div> -->
              <div class="footer-foot-left-content footer-foot-left-email">
                <img src="/images/icon/mail.png" alt="email" />
                <p><a href="mailto:globalsales998@gmail.com">globalsales998@gmail.com</a></p>
              </div>
              <div class="footer-foot-left-content footer-foot-left-phone">
                <img src="/images/icon/phone.png" alt="phone" />
                <p><a href="tel:1-300-88-3456">1-300-88-3456</a></p>
              </div>
              <div class="footer-foot-left-content footer-foot-left-company">
                <p>GLOBAL SALES AND MARKETING SDN. BHD. 201901028989 (1338318-X)</p>
              </div>
            </div>
            <div class="footer-head-right">
              <ul>
                <li v-if="false" @click="scrollTop">
                  <router-link :to="`${this.$store.state.multiLanguage}`">
                    {{ $t('our_beers') }}
                  </router-link>
                </li>
                <li @click="scrollTop">
                  <router-link :to="`${this.$store.state.multiLanguage + 'our_story'}`">
                    {{ $t('our_story') }}
                  </router-link>
                </li>
                <li @click="scrollTop">
                  <router-link :to="`${this.$store.state.multiLanguage}` + 'event_services'">
                    {{ $t('events_services') }}
                  </router-link>
                </li>
                <li @click="scrollTop">
                  <router-link :to="`${this.$store.state.multiLanguage}` + 'shop'">
                    {{ $t('shop') }}
                  </router-link>
                </li>
                <li @click="scrollTop">
                  <router-link :to="`${this.$store.state.multiLanguage + 'store_locator'}`">
                    {{ $t('store_locator') }}
                  </router-link>
                </li>
                <li @click="scrollTop">
                  <router-link :to="`${this.$store.state.multiLanguage + 'careers'}`">
                    {{ $t('careers') }}
                  </router-link>
                </li>
                <li @click="scrollTop">
                  <router-link :to="`${this.$store.state.multiLanguage + 'faq'}`">
                    {{ $t('faq') }}
                  </router-link>
                </li>
                <li @click="scrollTop">
                  <router-link :to="`${this.$store.state.multiLanguage + 'feedback'}`">
                    {{ $t('feedback.navigation') }}
                  </router-link>
                </li>
              </ul>
            </div>
            <img class="footer-head-right-choice" src="/images/footer/footer_consumer_choice.png" />
          </div>
          <div class="footer-head" id="footer-head-mobile">
            <!-- <div class="footer-head-left">
              <img src="/images/logo/1602_logo.png" alt="1602 logo" />
            </div> -->
            <div class="footer-head-left">
              <div class="footer-foot-left-content footer-foot-left-name">
                <p>{{ $t('craft_beer_brewery') }}</p>
              </div>
              <div class="footer-foot-left-content footer-foot-left-email">
                <img src="/images/icon/mail.png" alt="email" />
                <p><a href="mailto:globalsales998@gmail.com">globalsales998@gmail.com</a></p>
              </div>
              <div class="footer-foot-left-content footer-foot-left-phone">
                <img src="/images/icon/phone.png" alt="phone" />
                <p><a href="tel:1-300-88-3456">1-300-88-3456</a></p>
              </div>
              <!-- <div class="footer-foot-left-content footer-foot-left-company">
                <p>GLOBAL SALES AND MARKETING SDN. BHD. 201901028989 (1338318-X)</p>
              </div> -->
            </div>
            <div class="footer-head-right">
              <p>{{ $t('customer_service') }}</p>
              <ul>
                <li>
                  <router-link :to="`${this.$store.state.multiLanguage + 'store_locator'}`">
                    {{ $t('store_locator') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="`${this.$store.state.multiLanguage + 'careers'}`">
                    {{ $t('careers') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="`${this.$store.state.multiLanguage + 'faq'}`">
                    {{ $t('faq') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="`${this.$store.state.multiLanguage + 'feedback'}`">
                    {{ $t('feedback.navigation') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <p class="footer-foot-title">{{ $t('our_location') }}</p>
          <div class="footer-foot">
            <div class="footer-foot-left">
              <div class="footer-foot-left-container">
                <div class="footer-foot-left-content footer-foot-left-branch">
                  <img class="footer-foot-left-content footer-foot-left-branch-icon" src="/images/icon/location.png" alt="location" />
                  <p class="footer-foot-left-content footer-foot-left-branch-title">{{ $t('kuching') }}</p>
                </div>
                <p class="footer-foot-left-content footer-foot-left-branch-address">Ground Floor, Lot 19695, No. 17, Block 11, Block B1, Saradise Kuching, Jalan Stutong, 93350 Kuching, Sarawak.</p>
              </div>
              <div class="footer-foot-left-container">
                <div class="footer-foot-left-content footer-foot-left-branch">
                  <img class="footer-foot-left-content footer-foot-left-branch-icon" src="/images/icon/location.png" alt="location" />
                  <p class="footer-foot-left-content footer-foot-left-branch-title">{{ $t('sibu') }}</p>
                </div>
                <p class="footer-foot-left-content footer-foot-left-branch-address">Lot 158, S/L 4, No.24, 1st Floor, Lorong Seduan 98000 Sibu, Sarawak.</p>
              </div>
              <div class="footer-foot-left-container">
                <div class="footer-foot-left-content footer-foot-left-branch">
                  <img class="footer-foot-left-content footer-foot-left-branch-icon" src="/images/icon/location.png" alt="location" />
                  <p class="footer-foot-left-content footer-foot-left-branch-title">{{ $t('sabah') }}</p>
                </div>
                <p class="footer-foot-left-content footer-foot-left-branch-address">Lot 1, Realton Industrial Park, Inanam Laut, Jalan Burung Keleto Off, Jalan Tuaran By Pass, 88300 Kota Kinabalu, Sabah.</p>
              </div>
              <div class="footer-foot-left-container">
                <div class="footer-foot-left-content footer-foot-left-branch">
                  <img class="footer-foot-left-content footer-foot-left-branch-icon" src="/images/icon/location.png" alt="location" />
                  <p class="footer-foot-left-content footer-foot-left-branch-title">{{ $t('miri') }}</p>
                </div>
                <p class="footer-foot-left-content footer-foot-left-branch-address">Lot 9611, Block 1, Lambir Land District, Assar Commercial Centre, Jalan Miri By-Pass.</p>
              </div>
            </div>
            <div class="footer-foot-right">
              <img class="footer-foot-right-choice-mobile" src="/images/footer/footer_consumer_choice.png" />

              <p class="footer-foot-right-follow">{{ $t('follow_us') }}</p>
              <div class="footer-foot-right-social">
                <a href="https://www.facebook.com/1602craftbeer/" target="_blank"><img src="/images/icon/facebook.png" alt="facebook" /></a>
                <a href="https://www.instagram.com/1602craftbeer_/" target="_blank"><img src="/images/icon/instagram.png" alt="instagram" /></a>
                <a href="https://www.youtube.com/@CraftBeer-db2sn" target="_blank"><img src="/images/icon/youtube.png" alt="youtube" /></a>
              </div>
              <p>©2024 {{ $t('craft_beer_brewery') }}</p>
            </div>
          </div>
          <div class="footer-foot-right-terms">
            <router-link :to="`${this.$store.state.multiLanguage + 'terms_conditions'}`" @click="scrollTop"><span>{{ $t('terms_condition') }}</span></router-link>
            <router-link :to="`${this.$store.state.multiLanguage + 'privacy_policy'}`" @click="scrollTop"><span>{{ $t('privacy') }}</span></router-link>
            <router-link :to="`${this.$store.state.multiLanguage + 'refund_policy'}`" @click="scrollTop"><span>{{ $t('refund_policy') }}</span></router-link>
            <router-link :to="`${this.$store.state.multiLanguage + 'shipping_info'}`" @click="scrollTop"><span>{{ $t('shipping_info_title') }}</span></router-link>
          </div>
        </div>
      </div>

    </section>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      multiLanguage: this.$store.state.multiLanguage
    };
  },
  async mounted() {
    // this.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;

    this.$i18n.locale = localStorage.getItem('language');
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

